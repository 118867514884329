import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, FormFieldsObject } from '../../Form.tsx';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Center,
  Spinner,
  useAlert,
} from '@tonic/central-specialties-ui-themed';
import { Text } from '@gluestack-ui/themed';

interface JobNumberFormProps {
  partialJobNumber?: Schemas['JobResponse'];
  onSuccess?: () => void;
  formProps?: Partial<React.ComponentProps<typeof Form>>;
}

export const JobNumberForm = ({
  partialJobNumber,
  onSuccess,
  formProps,
}: JobNumberFormProps) => {
  const { alert } = useAlert();

  const mode = useMemo<'create' | 'edit'>(() => {
    switch (true) {
      case partialJobNumber && 'number' in partialJobNumber:
        return 'edit';
      default:
        return 'create';
    }
  }, [partialJobNumber]);

  ///-----------------------------------------------------------------------
  // LOAD FULL Job ENTITY
  ///-----------------------------------------------------------------------
  // Necessary because partialJobNumber does not provide all the data needed (mainly job codes)
  const [jobNumber, setJobNumber] = useState<
    Schemas['JobResponseWithCodes'] | undefined
  >();

  useEffect(() => {
    if (!partialJobNumber) {
      setJobNumber(undefined);
      return;
    }
    DB.GET('/jobs/{id}', {
      params: { path: { id: partialJobNumber.id } },
    }).then((res: { data: Schemas['JobResponseWithCodes'] }) => {
      setJobNumber(res.data);
    });
  }, [partialJobNumber]);

  ///-----------------------------------------------------------------------
  // FETCH & FORMAT OPTIONS FOR FIELDS THAT NEED THEM
  ///-----------------------------------------------------------------------
  const { data: divisionCodes, isPending: jobCodesPending } = useQuery({
    queryKey: ['jobCodes.divisionCodes'],
    queryFn: async () =>
      DB.GET('/job-codes').then(
        (res: { data: Schemas['JobCodesByTypeResponse'] }) =>
          res.data.divisionCodes,
      ),
  });

  const divisionCodeOptions = useMemo(() => {
    console.log('divisionCodes', divisionCodes);
    return divisionCodes
      ? Object.fromEntries(
          divisionCodes.map((codeObj) => [
            `${codeObj.code} - ${codeObj.description}`,
            codeObj.id,
          ]),
        )
      : {};
  }, [divisionCodes]);

  ///-----------------------------------------------------------------------
  // MUTATION REQUESTS
  ///-----------------------------------------------------------------------
  // CREATE
  const { mutateAsync: createJob } = useMutation({
    mutationFn: (data: Schemas['JobRequest']) =>
      DB.POST('/jobs', { body: data }),
  });

  // UPDATE
  const { mutateAsync: updateJob } = useMutation({
    mutationFn: ({ id, data }: { id: string; data: Schemas['JobRequest'] }) =>
      DB.POST('/jobs/{id}', { params: { path: { id } }, body: data }),
  });

  ///-----------------------------------------------------------------------
  // DEFINE FIELDS
  ///-----------------------------------------------------------------------
  const formFields: FormFieldsObject = useMemo(
    () => ({
      number: {
        type: 'Text',
        dataFieldKey: 'number',
        label: 'Job Number',
        required: true,
      },
      description: {
        type: 'Text',
        dataFieldKey: 'description',
        label: 'Job name',
        required: true,
      },
      divisionCodes: {
        type: 'CheckboxGroup',
        dataFieldKey: 'divisionCodes',
        label: 'Division code',
        options: divisionCodeOptions,
        required: true,
        checkboxGroupProps: {
          flexDirection: 'column',
          flexWrap: 'wrap',
          maxHeight: (Object.keys(divisionCodeOptions).length + 1) * 15,
        },
      },
      questions: {
        type: 'CustomStrings',
        dataFieldKey: 'additionalQuestions',
        label: 'Additional questions',
        addButtonText: 'Add question',
        placeholder: 'Enter a yes/no question',
        // Make sure they don't submit empty strings
        preSubmitTransform: (val) => val.filter((v) => v),
        required: true,
      },

      address: {
        type: 'Address',
        dataFieldKey: ['address', 'latitude', 'longitude'],
        label: 'Location',
        required: true,
      },
    }),
    [divisionCodeOptions],
  );

  const [defaults, setDefaults] = useState(false);

  useEffect(() => {
    if (mode === 'edit' && !jobNumber) {
      setDefaults(false);
      return;
    }
    setDefaults({
      number: jobNumber?.number || '',
      description: jobNumber?.description || '',
      address: jobNumber?.address || '',
      // lat and long aren't yet on Job entities, but they may be, and we'll want the address form field to work with that
      latitude: jobNumber?.latitude || 0,
      longitude: jobNumber?.longitude || 0,
      divisionCodes: (jobNumber?.jobCodes?.divisionCodes || []).map(
        (dc) => dc.id,
      ),
      additionalQuestions: jobNumber?.additionalQuestions || [],
    });
  }, [mode, jobNumber, divisionCodeOptions]);

  const onSubmit = async (formData: any) => {
    const data: Schemas['JobRequest'] = {
      number: formData.number,
      description: formData.description,
      address: formData.address,
      additionalQuestions: formData.additionalQuestions,
      jobCodeIds: [...formData.divisionCodes],
    };
    try {
      const res = await (mode === 'create'
        ? createJob(data)
        : updateJob({ id: jobNumber!.id, data }));
      if (res.error) {
        throw res.error;
      }
      alert({
        status: 'success',
        message: `Job ${mode === 'create' ? 'created' : 'updated'} successfully.`,
        timeout: 5000,
      });
      if (onSuccess) {
        setDefaults(formData);
        onSuccess();
      }
    } catch (e) {
      alert({
        status: 'error',
        message: `Error ${mode === 'create' ? 'creating' : 'updating'} job.`,
        timeout: 10000,
      });
    }
  };

  const checkCanSubmit = useCallback(
    (formData: typeof defaults): boolean =>
      Object.entries(formData).some(([key, value]) => {
        if (key === 'divisionCodes' || key === 'additionalQuestions') {
          return (
            value.length !== defaults[key].length ||
            !value.every((v) => defaults[key].includes(v))
          );
        }
        return value !== defaults[key];
      }),
    [defaults],
  );

  if (!defaults || (mode === 'edit' && !jobNumber) || jobCodesPending) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (!divisionCodes) {
    return (
      <Center>
        <Text>Error loading job codes. Please try again later.</Text>
      </Center>
    );
  }

  return (
    <Form
      fields={formFields}
      formData={defaults}
      onSubmit={onSubmit}
      buttonProps={{ width: '$32' }}
      checkCanSubmit={checkCanSubmit}
      {...formProps}
    />
  );
};
