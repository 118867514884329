import { useCallback, useEffect, useMemo, useState } from 'react';
import { PageWrapper } from '../../../components/general/PageWrapper.tsx';
import {
  Box,
  Button,
  ButtonIcon,
  ButtonText,
  Center,
  HStack,
  Icon,
  Text,
  VStack,
} from '@gluestack-ui/themed';
import { Icons } from '@tonic/central-specialties-ui-themed';
import { DateSelectStrip } from '../../../components/general/EntityCardExplorer/DateSelect/DateSelectStrip.tsx';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import moment, { Moment } from 'moment';
import {
  FilterButton,
  FiltersData,
} from '../../../components/general/filtering/FilterButton';
import {
  TimeCardCard,
  TimeCardDaySummary,
} from '../../../components/TimeCards/TimeCardCard.tsx';
import { useQuery } from '@tanstack/react-query';
import { LoadingLogoAndSpinner } from '../../../components/general/LoadingLogoAndSpinner.tsx';
import { EmployeeWorkdaySummaryModal } from '../../../components/TimeCards/EmployeeWorkdaySummaryModal.tsx';
import { NewTimeCardEntryModal } from '../../../components/general/Form/specific/TimeCardEntry/NewTimeCardEntryModal.tsx';
import { EntityCardColumnsView } from '../../../components/general/EntityCardExplorer/EntityCardColumnsView/EntityCardColumnsView.tsx';
import { downloadCSVFile } from '../../../utils/downloadObjectsAsCSV.ts';
import { UnlockUserTimeEntryDialog } from '../../../components/TimeCards/UnlockUserTimeEntryDialog/UnlockUserTimeEntryDialog.tsx';

const roundToNearest15Minutes = (timestamp: string): string => {
  const time = moment(timestamp);
  const remainder = time.minutes() % 15;
  if (remainder !== 0) {
    time.minutes(time.minutes() - remainder);
  }
  time.seconds(0);
  time.milliseconds(0);
  return time.toISOString();
};

const calcTimeEntryDuration = (
  timeCardEntry: Schemas['TimeCardEntryModel'],
) => {
  const startTime = new Date(timeCardEntry.startTime);
  // Support certain no-work-entry types that don't have an end time
  const endTime = timeCardEntry?.endTime
    ? new Date(timeCardEntry.endTime)
    : startTime;
  const durationInMilliseconds = endTime - startTime;
  return durationInMilliseconds / 3600000;
};

const calcTotalTime = (timeCardEntries: Schemas['TimeCardEntryModel']) =>
  timeCardEntries.reduce((acc, timeCardEntry) => {
    const durationInHours = calcTimeEntryDuration(timeCardEntry);
    acc += durationInHours;
    return acc;
  }, 0);

interface UseTimeCardsAndEntriesData {
  daySummaries:
    | Schemas['TimeCardUserDaySummaryList']['daySummaries']
    | undefined;
  filterOptions: Schemas['TimeCardUserDaySummaryList']['filters'] | null;
  /**
   * True if fetching time cards or time card entries, either initially or after a timeframe change
   */
  pending: boolean;
  /**
   * True only if fetching time cards for the first time
   */
  initialFetchPending: boolean;
  refetchTimeCards: () => void;
  error: Error | null;
}

export type TimeCard = Schemas['TimeCardUserDaySummary'] & {
  timeCardEntryIds: string[];
  timeCardEntries: Schemas['TimeCardEntryModel'][];
  totalTime: number;
  employee: Schemas['UserModel'] | null; // null for Missing entries, we only need their name and ID for unlocking purposes;
  hasMessages: boolean;
};

const useTimeCardsAndEntries = (
  timeframe: TimeframeData,
): UseTimeCardsAndEntriesData => {
  const [initialFetchPending, setInitialFetchPending] = useState(true);
  const [error] = useState<Error | null>(null);
  const [filterOptions, setFilterOptions] =
    useState<Schemas['TimeCardAdminFilters']>(null);
  const [daySummaries, setDaySummaries] = useState<
    Schemas['TimeCardUserDaySummaryList']['daySummaries']
  >([]);

  const {
    isPending: daySummariesPending,
    error: daySummariesFetchError,
    data: payrollAdminData,
    refetch: refetchDaySummaries,
  } = useQuery({
    queryKey: ['timecards', timeframe.startDate, timeframe.endDate],
    queryFn: () =>
      DB.GET('/time-card-entries/payroll-admin', {
        params: {
          query: {
            startTime: timeframe.startDate,
            endTime: timeframe.endDate,
          },
        },
      }).then((v: { data: Schemas['TimeCardUserDaySummaryList'] }) => {
        return v.data;
      }) as TimeCardDaySummary[],
  });

  useEffect(() => {
    if (payrollAdminData) {
      setInitialFetchPending(false);
      setDaySummaries(payrollAdminData.daySummaries);
      setFilterOptions(payrollAdminData.filters);
    }
  }, [payrollAdminData]);

  return {
    daySummaries,
    filterOptions,
    pending: daySummariesPending,
    initialFetchPending,
    refetchTimeCards: refetchDaySummaries,
    error: error || daySummariesFetchError,
  };
};

const startDay = moment().startOf('week').utc().toDate().toISOString();
const endDay = moment().endOf('week').utc().toDate().toISOString();

type TimeframeData = {
  startDate: string;
  endDate: string;
};

const createFiltersData = (
  _options: Schemas['TimeCardAdminFilters'],
): FiltersData<TimeCard> => ({
  Employee: {
    path: ['fullName'],
  },
  Supervisor: {
    path: ['supervisors', 'name'],
    formatOptions: 'noNull',
  },
  'Job number': {
    path: ['jobs', 'number'],
    formatOptions: 'noNull',
  },
  'Has messages': {
    path: ['hasMessages'],
  },
});

export const TimeCardsPage = () => {
  const [weekTimeframe, setWeekTimeframe] = useState<TimeframeData>({
    startDate: moment().startOf('week').format('YYYY-MM-DD'),
    endDate: moment().endOf('week').format('YYYY-MM-DD'),
  });
  const [timeframe, setTimeframe] = useState<TimeframeData>({
    startDate: startDay,
    endDate: endDay,
  });

  const [dateSelected, setDateSelected] = useState<Moment>(moment());
  const [showUnlockModalFor, setShowUnlockModalFor] = useState<TimeCard | null>(
    null,
  );
  const [timeCardSelected, setTimeCardSelected] = useState<null | TimeCard>(
    null,
  );

  const {
    daySummaries: timeCards,
    filterOptions,
    initialFetchPending,
    pending,
    refetchTimeCards,
    error,
  } = useTimeCardsAndEntries(timeframe, dateSelected);

  const [filteredTimeCards, setFilteredTimeCards] = useState<TimeCard[]>([]);

  const filtersData = useMemo(
    () => createFiltersData(filterOptions),
    [filterOptions],
  );

  // Record of date strings in the format YYYY-MM-DD and the number of non-empty daySummaries for that date
  const timeCardDateCounts = useMemo(() => {
    // Make sure days that days don't show checkmarks before updating when user scrolls past the currently fetched date boundary
    if (pending) {
      return {};
    }
    const startDate = moment(timeframe.startDate);
    const endDate = moment(timeframe.endDate);
    const newCounts: Record<string, number> = {};

    if (filteredTimeCards && startDate && endDate) {
      for (let date = startDate; date <= endDate; date.add(1, 'day')) {
        const dateStr = date.format('YYYY-MM-DD');
        newCounts[dateStr] = filteredTimeCards.filter((daySummary) => {
          // check that dateStr is between the truck request's start and end dates
          return (
            date.isSame(moment(daySummary.date).startOf('day'), 'day') &&
            ['Submitted', 'Denied', 'Approved'].includes(daySummary.status) // TODO: ensure that these are the only statuses that should be counted
          );
        }).length;
      }
    }
    return newCounts;
  }, [filteredTimeCards, pending, timeframe.startDate, timeframe.endDate]);

  const [showNewTimeCardEntryModal, setShowNewTimeCardEntryModal] =
    useState(false);

  const exportWeek = useCallback(async () => {
    try {
      const response = await DB.GET('/time-card-entries/export-small', {
        parseAs: 'text',
        params: {
          query: {
            startTime: weekTimeframe.startDate,
            endTime: weekTimeframe.endDate,
          },
        },
      });
      if (!response.data) {
        throw new Error('Network response was not ok');
      }
      const name =
        'TimeCards_' + weekTimeframe.startDate + '_to_' + weekTimeframe.endDate;
      downloadCSVFile(response.data, name);
    } catch (error) {
      console.error('Download error:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekTimeframe.startDate, weekTimeframe.endDate, timeCards]);

  const exportWeekEntries = useCallback(async () => {
    try {
      const response = await DB.GET('/time-card-entries/export-large', {
        parseAs: 'text',
        params: {
          query: {
            startTime: weekTimeframe.startDate,
            endTime: weekTimeframe.endDate,
          },
        },
      });
      if (!response.data) {
        throw new Error('Network response was not ok');
      }
      const name =
        'TimeCard_entries_' +
        weekTimeframe.startDate +
        '_to_' +
        weekTimeframe.endDate;

      downloadCSVFile(response.data, name);
    } catch (error) {
      console.error('Download error:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekTimeframe.startDate, weekTimeframe.endDate, timeCards]);

  const exportSubmittedAndMissing = useCallback(async () => {
    try {
      const date = dateSelected.format('YYYY-MM-DD');
      const name = 'TimeCard_entries_submitted&missing_' + date;
      await DB.GET('/time-card-entries/submitted-missing-time-export', {
        params: {
          query: {
            date: date,
          },
        },
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        parseAs: 'arrayBuffer',
      }).then((response) => {
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}.xlsx`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.error('Download error:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelected, timeCards]);

  // Updates range to fetch within when user scrolls past the default timeframe
  const onChangeWeekView = (start: Moment, end: Moment) => {
    // Update the start and end of just the week we are looking at
    setWeekTimeframe({
      startDate: start.format('YYYY-MM-DD'),
      endDate: end.format('YYYY-MM-DD'),
    });

    // Set the selected date to the start of the week if selected date is not in the week
    if (!dateSelected.isBetween(start, end, 'day', '[]')) {
      setDateSelected(start);
    }

    // Make sure timeframe for fetch is updated if the user scrolls past the current timeframe
    const tempTimeframe = {
      startDate: moment(timeframe.startDate),
      endDate: moment(timeframe.endDate),
    };
    let needsToChange = false;
    // console.log('onChangeWeekView', start.toString(), end.toString());
    if (start.isBefore(tempTimeframe.startDate, 'day')) {
      tempTimeframe.startDate = start;
      needsToChange = true;
    }
    if (end.isAfter(tempTimeframe.endDate, 'day')) {
      tempTimeframe.endDate = end;
      needsToChange = true;
    }
    if (needsToChange) {
      setTimeframe({
        startDate: tempTimeframe.startDate.format('YYYY-MM-DD'),
        endDate: tempTimeframe.endDate.format('YYYY-MM-DD'),
      });
    }
  };

  const convertToCST = (utcTimeString: string | Date) => {
    // Parse the input UTC time string
    const utcDate = new Date(utcTimeString);

    // Convert to CST (UTC-5)
    const cstOffset = -5 * 60 * 60000;
    const cstDate = new Date(utcDate.getTime() + cstOffset);

    // Get hours in CST
    const cstHours = cstDate.getUTCHours();

    // If the time is 7:00 PM or later (19:00 or more), adjust the date to the previous day
    if (cstHours >= 19) {
      // Format the new date and time
      const newDateString = cstDate.toISOString().split('T')[0]; // Get the date part
      const newTimeString = cstDate.toTimeString().split(' ')[0].slice(0, 5); // Get the time part (HH:MM)
      const newDate = cstDate.getDate();

      return {
        newDateString,
        newTimeString,
        newDate,
        isSameDate: newDate === dateSelected.date(),
        cstDate,
      };
    }
    return {
      newDateString: null,
      newTimeString: null,
      newDate: null,
      isSameDate:
        new Date(utcDate).getUTCDate() === moment(dateSelected).utc().date(),
      cstDate: null,
    };
  };

  const handleSelectedTimeCard = async (selectedCard: TimeCard) => {
    if (selectedCard) {
      const { employeeId } = selectedCard;
      const selectedDate = moment(dateSelected);
      const formattedSelectedDate = selectedDate.format('YYYY-MM-DD');
      // grab two days to account for late UTC entries of the next day that need to be converted to CST time
      const nextDay = selectedDate.add(2, 'day').format('YYYY-MM-DD');

      try {
        let entries = await DB.GET('/time-card-entries/employee/{employeeId}', {
          params: {
            path: { employeeId: employeeId },
            query: {
              startTime: formattedSelectedDate,
              endTime: nextDay,
            },
          },
        }).then((v: { data: Schemas['TimeCardEntryListRequest'] }) => {
          const selectedDateEntries = v.data.timeCardEntries.filter((entry) => {
            const { isSameDate } = convertToCST(`${entry.startTime}Z`);

            return isSameDate;
          });
          return selectedDateEntries;
        });

        // round all timestamps to 15 minute intervals
        entries = entries.map(({ startTime, endTime, ...restEntry }) => {
          return {
            startTime: roundToNearest15Minutes(startTime),
            endTime: roundToNearest15Minutes(endTime),
            ...restEntry,
          };
        });

        const detailedCard = {
          ...selectedCard,
          timeCardEntries: entries,
          employee: entries?.[0]?.employee || null,
          timeCardEntryIds: entries.map((tc) => tc.id),
          totalTime: calcTotalTime(entries),
        };
        setTimeCardSelected(detailedCard);
      } catch (e) {
        console.error('Error fetching summaries for employee ', {
          employeeId,
          error: e,
        });
        return false;
      }
    }
  };

  if (initialFetchPending) {
    return (
      <Center w="$full" h="$full">
        <LoadingLogoAndSpinner />
      </Center>
    );
  }

  if (error) {
    return (
      <Center w="$full" h="$full" gap="$6">
        <Icon as={Icons.AlertCircle} color="$error500" w="$16" h="$16" />
        <Text size="lg" color="$error500">
          There was an error retrieving time cards.
        </Text>
      </Center>
    );
  }

  return (
    <>
      <PageWrapper
        ToolbarContent={
          <>
            <HStack space="lg">
              <Button
                onPress={() => setShowNewTimeCardEntryModal(true)}
                size="lg"
                px="$4"
              >
                <ButtonIcon as={Icons.Plus} />
                <ButtonText>Add Time Entry</ButtonText>
              </Button>
              <Button size="lg" px="$4" variant="outline" onPress={exportWeek}>
                <ButtonIcon color="$primary700" as={Icons.Download} />
                <ButtonText>Export Week</ButtonText>
              </Button>
              <Button
                size="lg"
                px="$4"
                variant="outline"
                onPress={exportWeekEntries}
              >
                <ButtonIcon color="$primary700" as={Icons.Download} />
                <ButtonText>Export Week (Detailed)</ButtonText>
              </Button>
              <Button
                size="lg"
                px="$4"
                variant="outline"
                onPress={exportSubmittedAndMissing}
              >
                <ButtonIcon color="$primary700" as={Icons.Download} />
                <ButtonText>Export Submitted & Missing Time</ButtonText>
              </Button>
            </HStack>
            <HStack>
              <FilterButton
                data={timeCards}
                setFilteredData={setFilteredTimeCards}
                filtersData={filtersData}
              />
            </HStack>
          </>
        }
      >
        <VStack flex={1} w="$full" space="md" px="$5">
          <DateSelectStrip
            date={dateSelected}
            dateCounts={timeCardDateCounts}
            onDateSelected={setDateSelected}
            updateDateRange={onChangeWeekView}
          />
          <Box flex={1}>
            <HStack h="$full" flex={1} space="lg">
              <EntityCardColumnsView
                data={
                  filteredTimeCards.filter((item) =>
                    moment(item.date).isSame(dateSelected, 'day'),
                  ) as TimeCard[]
                }
                groupByPath={['status']}
                columnGroupings={[
                  {
                    value: 'Submitted',
                    indicatorDotVariant: 'success',
                  },
                  {
                    value: 'Denied',
                    indicatorDotVariant: 'warning',
                  },
                  {
                    value: 'Missing',
                    indicatorDotVariant: 'error',
                  },
                  { value: 'Approved' },
                ]}
                CardComponent={(cardProps) => (
                  <TimeCardCard
                    {...cardProps}
                    onPress={() => {
                      const timecard: TimeCard = cardProps.data;
                      if (timecard.status === 'Missing') {
                        setShowUnlockModalFor(timecard);
                      } else {
                        handleSelectedTimeCard(timecard);
                      }
                    }}
                  />
                )}
                searchByPaths={[
                  ['employee', 'fullName'],
                  ['employee', 'employeeNumber'],
                  ['fullName'],
                ]}
              />
            </HStack>
          </Box>
        </VStack>
      </PageWrapper>
      {showNewTimeCardEntryModal && (
        <NewTimeCardEntryModal
          isOpen={showNewTimeCardEntryModal}
          onClose={() => setShowNewTimeCardEntryModal(false)}
          targetDate={dateSelected}
          refetch={refetchTimeCards}
        />
      )}
      {!!timeCardSelected && !!dateSelected && (
        <EmployeeWorkdaySummaryModal
          timeCard={timeCardSelected}
          date={dateSelected.toDate()}
          isOpen={true}
          onClose={() => setTimeCardSelected(null)}
          openUnlockModal={(timeCard) => setShowUnlockModalFor(timeCard)}
          refetch={refetchTimeCards}
        />
      )}
      {!!showUnlockModalFor && (
        <UnlockUserTimeEntryDialog
          employee={{
            fullName: showUnlockModalFor.fullName,
            id: showUnlockModalFor.employeeId,
          }}
          isOpen={true}
          onClose={() => setShowUnlockModalFor(null)}
        />
      )}
    </>
  );
};
