import { DataStructureBase, DeepPath } from '@tonic/central-specialties-utils';
import {
  CardProps,
  IndicatorDot,
  IndicatorDotVariant,
  Spinner,
} from '@tonic/central-specialties-ui-themed';
import React, { useState } from 'react';
import { Box, Center, HStack, Text, VStack } from '@gluestack-ui/themed';
import { SearchBar } from '../../../SearchBar.tsx';
import { useSearchFilter } from '../../../../../hooks/useSearchFilter.ts';
import List from 'react-virtualized/dist/commonjs/List';
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer';
import {
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized/dist/commonjs/CellMeasurer';

export interface EntityCardColumnProps<T extends DataStructureBase> {
  items: T[];
  pending?: boolean;
  categoryName?: string;
  indicatorVariant?: IndicatorDotVariant;
  CardComponent: (props: { data: T; index: number }) => React.ReactElement;
  cardProps?: Partial<CardProps>;
  HeaderLeftComponent?: React.ComponentType;
  HeaderRightComponent?: React.ComponentType;
  /**
   * If passed, a searchbar will be shown, and any path of type T included will be matched against the search term
   */
  searchByPaths?: DeepPath<T>[] | 'all';
  containerProps?: React.ComponentProps<typeof VStack>;
}

export const EntityCardColumn = <T extends DataStructureBase>({
  items,
  pending = false,
  categoryName,
  indicatorVariant,
  CardComponent,
  HeaderLeftComponent,
  HeaderRightComponent,
  searchByPaths,
  containerProps,
  cardProps,
}: EntityCardColumnProps<T>) => {
  const [searchValue, setSearchValue] = useState('');
  const searchTermFilteredItems = useSearchFilter(
    items,
    searchValue,
    searchByPaths,
  );

  const cache = new CellMeasurerCache({
    defaultHeight: 120,
    fixedWidth: true,
  });

  return (
    <VStack
      flex={1}
      w="$full"
      h="$full"
      space="md"
      alignItems="center"
      {...containerProps}
    >
      <VStack w="$full" space="lg">
        <HStack w="$full" alignItems="center" justifyContent="space-between">
          {HeaderLeftComponent ? (
            <HStack flex={1}>
              <HeaderLeftComponent />
            </HStack>
          ) : (
            <HStack flex={HeaderRightComponent ? 1 : 0.2} />
          )}
          <HStack
            space="lg"
            alignItems="center"
            justifyContent="center"
            flex={2}
          >
            {categoryName && (
              <Text size="lg" bold>
                {categoryName}
              </Text>
            )}
            {!!indicatorVariant && !!items && items.length && (
              <IndicatorDot
                size="md"
                variant={indicatorVariant}
                count={items.length}
              />
            )}
          </HStack>
          {HeaderRightComponent ? (
            <HStack flex={1} justifyContent="flex-end">
              <HeaderRightComponent />
            </HStack>
          ) : (
            <HStack flex={HeaderLeftComponent ? 1 : 0.2} />
          )}
        </HStack>
        {!!searchByPaths && <SearchBar onChangeText={setSearchValue} />}
      </VStack>
      <Box flex={1} w="$full">
        {pending ? (
          <Center pt="$6">
            <Spinner />
          </Center>
        ) : !!searchTermFilteredItems && searchTermFilteredItems.length > 0 ? (
          <AutoSizer>
            {({ height, width }) => (
              <List
                style={{ paddingBottom: 30 }}
                height={height}
                width={width}
                rowCount={searchTermFilteredItems.length}
                rowHeight={cache.rowHeight}
                estimatedRowSize={130}
                overscanRowCount={5}
                deferredMeasurementCache={cache}
                rowRenderer={({ index, key, style, parent }) => {
                  const item = searchTermFilteredItems[index];
                  return (
                    <CellMeasurer
                      cache={cache}
                      parent={parent}
                      columnIndex={0}
                      rowIndex={index}
                      key={key}
                    >
                      {({ registerChild }) => (
                        <div
                          style={{ ...style, paddingBottom: 16 }}
                          ref={registerChild}
                        >
                          <CardComponent
                            data={item}
                            index={index}
                            {...cardProps}
                          />
                        </div>
                      )}
                    </CellMeasurer>
                  );
                }}
              />
            )}
          </AutoSizer>
        ) : (
          <Center h="$full" w="$full" pt="$6">
            <Text size="lg">No results</Text>
          </Center>
        )}
      </Box>
    </VStack>
  );
};
