import { TimeCardEntry } from '../../../../TimeCards/TimeCard.types.ts';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import {
  Center,
  Icons,
  Spinner,
  useAlert,
  useUser,
} from '@tonic/central-specialties-ui-themed';
import { Form, FormFieldsObject, FormProps } from '../../Form.tsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TimeframeData } from '../../FormField/specific';
import moment from 'moment-timezone';
import { useFormJobOptions } from '../../hooks/useFormJobOptions.ts';
import { Icon, Text } from '@gluestack-ui/themed';

const extractCodeOptions =
  <T extends 'divisionCodes' | 'laborCodes' | 'costCodes'>(key: T) =>
  (options: Schemas['JobCodesByTypeResponse']) => {
    if (!(options && (key in options || key === 'costCodes'))) {
      return {};
    }
    // Now that costCodes are nested within divisionCodes, we need to flatten them
    if (key === 'costCodes') {
      options = options.divisionCodes.reduce(
        (acc, divCode) => {
          acc.costCodes.push(...divCode.costCodes);
          return acc;
        },
        { ...options, costCodes: options.costCodes || [] },
      );
      options;
    }
    return Object.fromEntries(
      options?.[key]?.map((jcObj: Schemas['JobCodeItemResponse']) => {
        return [`${jcObj.code} - ${jcObj.description}`, jcObj.id];
      }),
    );
  };

interface TimeCardEntryFormProps {
  /**
   * If not provided, the form will be in create mode, otherwise it will be in edit mode
   */
  timeCardEntryId?: string;
  formProps?: Partial<FormProps>;
  targetDate?: Date;
  onSuccess?: () => void;
}

export const TimeCardEntryForm = ({
  timeCardEntryId,
  ...restProps
}: TimeCardEntryFormProps) => {
  const { alert } = useAlert();
  const {
    data: timeCardEntry,
    isPending,
    error,
  } = useQuery({
    queryKey: ['timeCardEntry', timeCardEntryId],
    queryFn: async () =>
      DB.GET('/time-card-entries/{id}', {
        params: { path: { id: timeCardEntryId } },
      }).then((res: { data: Schemas['TimeCardEntryModel'] }) => res.data),
    enabled: !!timeCardEntryId,
  });

  if (isPending && timeCardEntryId !== undefined) {
    return (
      <Center h="$full" minHeight="$96" flex={1}>
        <Spinner />
      </Center>
    );
  }

  if (error) {
    alert({
      message: 'There was an error fetching the time card entry.',
      status: 'error',
      timeout: 10000,
    });
    return (
      <Center w="$full" h="$full" gap="$6">
        <Icon as={Icons.AlertCircle} color="$error500" w="$16" h="$16" />
        <Text size="lg" color="$error500">
          There was an error retrieving time card entry {timeCardEntryId}
        </Text>
      </Center>
    );
  }
  return (
    (timeCardEntryId === undefined || !!timeCardEntry) && (
      <TimeCardEntryFormInner timeCard={timeCardEntry} {...restProps} />
    )
  );
};

interface TimeCardEntryFormInnerProps extends TimeCardEntryFormProps {
  /**
   * If not provided, the form will be in create mode, otherwise it will be in edit mode
   */
  timeCard?: TimeCardEntry;
}

export const TimeCardEntryFormInner = ({
  timeCard,
  formProps,
  targetDate,
  onSuccess,
}: TimeCardEntryFormInnerProps) => {
  const [mode, setMode] = useState<'create' | 'edit'>(
    timeCard && 'employee' in timeCard ? 'edit' : 'create',
  );
  const [defaults, setDefaults] = useState<
    Schemas['TimeCardEntryRequest'] | null
  >(null);
  const { alert } = useAlert();
  const user = useUser();

  useEffect(() => {
    setMode(timeCard && 'employee' in timeCard ? 'edit' : 'create');
    console.log('timeCard: ', timeCard);
  }, [timeCard]);

  ///-----------------------------------------------------------------------
  // FORM OPTIONS DATA FETCHING & STATE
  ///-----------------------------------------------------------------------
  // EMPLOYEE OPTIONS
  const [employeeOptions, setEmployeeOptions] = useState<Record<
    string,
    string
  > | null>(null);

  // Populate employee options if we're in create mode
  useEffect(() => {
    // if this is a new timecard entry (we're in create mode), we to populate a list of employee id options
    if (mode === 'create') {
      if (!targetDate) {
        alert({
          status: 'error',
          message: 'No target date selected for new time card entry!',
          timeout: 10000,
        });
      }
      DB.GET('/users').then((res: { data: Schemas['UserModel'][] }) => {
        setEmployeeOptions(
          Object.fromEntries(
            res.data
              .map((u) => [u.fullName, u.id])
              .sort((a, b) => a[0].localeCompare(b[0])), // Sort by name,
          ),
        );
      });
    } else {
      setEmployeeOptions(null);
    }
  }, [mode]);

  // Job options
  const {
    allJobs,
    jobOptions,
    isPending: jobsPending,
    error: _jobsFetchError,
  } = useFormJobOptions();

  const [selectedJobId, setSelectedJobId] = useState<string | null>(
    timeCard?.job?.id || null,
  );
  const [selectedCostCodeId, setSelectedCostCodeId] = useState<string | null>(
    null,
  );
  const [selectedDivisionCodeId, setSelectedDivisionCodeId] = useState<
    string | null
  >(null);
  const [selectedLaborCodeId, setSelectedLaborCodeId] = useState<string | null>(
    null,
  );
  const [enteredEquipmentNumber, setEnteredEquipmentNumber] = useState<
    string | null
  >('');
  const [equipmentEntityPending, setEquipmentEntityPending] = useState(false);
  const [enteredEquipmentEntity, setEnteredEquipmentEntity] = useState<
    Schemas['EquipmentItemResponse'] | null
  >(null);

  useEffect(() => {
    if (selectedJobId !== timeCard?.job?.id) {
      setSelectedDivisionCodeId(null);
    }
  }, [selectedJobId]);

  const {
    data: associatedJobCodes,
    isPending: associatedJobCodesPending,
    error: associatedJobCodesFetchError,
  } = useQuery({
    queryKey: ['jobCodesByType', selectedJobId],
    queryFn: async () => {
      console.log('fetching job codes for job: ', selectedJobId);
      return await DB.GET('/jobs/{id}/job-codes', {
        params: { path: { id: selectedJobId } },
      }).then((res: { data: Schemas['JobCodesByTypeResponse'] }) => res.data);
    },
    enabled: !!selectedJobId,
  });

  // EQUIPMENT OPTIONS
  const {
    data: equipmentOptions,
    isPending: equipmentPending,
    error: equipmentFetchError,
  } = useQuery({
    queryKey: ['equipmentOptionsFor', selectedLaborCodeId],
    queryFn: () =>
      DB.GET('/equipment/labor-code/{laborCodeId}', {
        params: {
          path: {
            laborCodeId: selectedLaborCodeId,
          },
        },
      }).then(
        (res: { data: Schemas['EquipmentListResponse'] }) => res.data.equipment,
      ),
    enabled: !!selectedLaborCodeId,
  });

  const fetchEquipmentByNumber = useCallback(
    async (equipmentNumber: string) => {
      setEquipmentEntityPending(true);
      return await DB.GET('/equipment/by-number/{number}', {
        params: {
          path: { number: equipmentNumber },
        },
      }).then((res: { data: Schemas['EquipmentItemResponse'] }) => {
        setEquipmentEntityPending(false);
        console.log('retrieved equipment entity: ', res);
        return res?.data ? res.data : null;
      });
    },
    [],
  );

  useEffect(() => {
    if (enteredEquipmentNumber) {
      if (Array.isArray(equipmentOptions) && equipmentOptions.length > 0) {
        const matchingEquipment = equipmentOptions.find(
          (eq) => eq.number === enteredEquipmentNumber,
        );
        setEquipmentEntityPending(false);
        setEnteredEquipmentEntity(matchingEquipment || null);
      } else {
        setEquipmentEntityPending(true);
        fetchEquipmentByNumber(enteredEquipmentNumber).then(
          (res: Schemas['EquipmentItemResponse'] | null) => {
            setEquipmentEntityPending(false);
            setEnteredEquipmentEntity(res);
          },
        );
      }
    } else {
      setEquipmentEntityPending(false);
      setEnteredEquipmentEntity(null);
    }
  }, [equipmentOptions, enteredEquipmentNumber]);

  useEffect(() => {
    console.log('equipment data: ', {
      equipmentOptions,
      enteredEquipmentNumber,
      enteredEquipmentEntity,
      equipmentEntityPending,
    });
  }, [
    equipmentOptions,
    enteredEquipmentNumber,
    enteredEquipmentEntity,
    equipmentEntityPending,
  ]);

  useEffect(() => {
    if (associatedJobCodesFetchError) {
      alert({
        status: 'error',
        message:
          'There was an error fetching job codes associated with this job number.',
      });
    } else if (equipmentFetchError) {
      alert({
        status: 'error',
        message:
          'There was an error fetching equipment options associated with this labor code.',
      });
    }
  }, [alert, equipmentFetchError, associatedJobCodesFetchError]);

  // SUPERVISOR OPTIONS
  const { data: supervisorOptions, isPending: supervisorsPending } = useQuery({
    queryKey: ['supervisors'],
    queryFn: async () =>
      DB.GET('/users/{userId}/supervisors', {
        params: {
          path: { userId: mode === 'edit' ? timeCard?.employee?.id : user.id },
        },
      }).then((res: { data: Schemas['UserModel'][] }) => res.data),
  });

  ///-----------------------------------------------------------------------
  // SUBMISSION HANDLERS
  ///-----------------------------------------------------------------------
  const { mutateAsync: updateTimeCardEntry } = useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: string;
      data: Schemas['TimeCardEntryRequest'];
    }) =>
      DB.POST('/time-card-entries/{id}', {
        params: {
          path: {
            id,
          },
        },
        body: data,
      }),
  });

  const { mutateAsync: createTimeCardEntry } = useMutation({
    mutationFn: (data: Schemas['TimeCardEntryRequest']) =>
      DB.POST('/time-card-entries', {
        body: data,
      }),
  });

  const submit = useCallback(
    async (data: typeof defaults) => {
      const {
        employeeId,
        jobId,
        laborCodeId,
        divisionCodeId,
        costCodeId,
        equipmentNumber,
        supervisorId,
        startTime: startTimeHoursMinutes,
        endTime: endTimeHoursMinutes,
        isRepair,
        isNoWorkEntry,
        noWorkType,
        workDescription,
      } = data;

      const equipmentId = equipmentOptions?.find(
        (eq) => eq.number === equipmentNumber,
      )?.id;

      let { startTime, endTime } = joinTimeframeDataToDate({
        startTime: startTimeHoursMinutes,
        endTime: endTimeHoursMinutes,
      });

      // Convert back to UTC before sending
      startTime = moment(startTime)
        .tz('America/Chicago', true)
        .utc()
        .toISOString();
      endTime = moment(endTime).tz('America/Chicago', true).utc().toISOString();

      // Create the actual payload, nullifying irrelevant fields for no-work entries
      const payload: Schemas['TimeCardEntryRequest'] = {
        employeeId,
        jobId: isNoWorkEntry ? null : jobId,
        laborCodeId: isNoWorkEntry ? null : laborCodeId,
        divisionCodeId: isNoWorkEntry ? null : divisionCodeId,
        costCodeId: isNoWorkEntry ? null : costCodeId,
        equipmentId: isNoWorkEntry ? null : equipmentId,
        supervisorId:
          isNoWorkEntry && !['PTO', 'ESST'].includes(noWorkType)
            ? null
            : supervisorId,
        startTime,
        endTime:
          isNoWorkEntry && !['PTO', 'ESST'].includes(noWorkType)
            ? null
            : endTime,
        isRepair: isNoWorkEntry ? false : isRepair,
        isNoWorkEntry,
        noWorkType: isNoWorkEntry ? noWorkType : null,
        workDescription:
          data?.isNoWorkEntry === true &&
          !['PTO', 'ESST'].includes(data?.noWorkType)
            ? data.noWorkType
            : workDescription,
      };

      const handleError = (
        e: any,
        fallbackAlertMessage: string,
      ): Record<string, string> | void => {
        if (
          'details' in e &&
          Array.isArray(e.details) &&
          e.details.length > 0
        ) {
          const errors: Record<string, string> = {};
          for (const eMessage of e.details) {
            if (eMessage.toLowerCase().includes('requires equipment')) {
              errors.equipmentNumber = eMessage;
            } else if (eMessage.toLowerCase().includes('overlapping time')) {
              errors.duration = eMessage;
            }
          }
          if (Object.keys(errors).length === 0) {
            errors.SUBMIT = e.details[0];
          }
          return errors;
        }
        alert({
          status: 'error',
          message: fallbackAlertMessage,
          timeout: 10000,
        });
      };

      switch (mode) {
        case 'create':
          try {
            // Certain api errors don't throw automatically
            const { error } = await createTimeCardEntry(payload);
            if (error) {
              console.error(error);
              throw error;
            }
            alert({
              status: 'success',
              message: 'Time card entry created successfully',
              timeout: 5000,
            });
            onSuccess && onSuccess();
          } catch (e) {
            console.error(e);
            return handleError(
              e,
              'There was an error creating this time card entry.',
            );
          }
          break;
        case 'edit':
          try {
            // Certain api errors don't throw automatically
            const { error } = await updateTimeCardEntry({
              id: timeCard?.id,
              data: payload,
            });
            if (error) {
              console.error(error);
              throw error;
            }
            alert({
              status: 'success',
              message: 'Time card entry updated successfully',
              timeout: 5000,
            });
            onSuccess && onSuccess();
          } catch (e) {
            console.error(e);
            return handleError(
              e,
              'There was an error updating this time card entry.',
            );
          }
          break;
        default:
          console.error('Invalid mode set in TimeCardEntryForm: ', mode);
          break;
      }
    },
    [
      mode,
      updateTimeCardEntry,
      createTimeCardEntry,
      defaults,
      alert,
      onSuccess,
      timeCard?.id,
      equipmentOptions,
    ],
  );

  ///-----------------------------------------------------------------------
  /// SETTING DEFAULTS
  ///-----------------------------------------------------------------------
  const formatTimestampForTimeInput = (timestamp: string): string => {
    if (!timestamp) {
      return '00:00';
    }
    return moment(timestamp).utc(true).tz('America/Chicago').format('HH:mm');
  };

  useEffect(() => {
    setDefaults({
      employeeId: timeCard?.employee?.id || '',
      jobId: timeCard?.job?.id || '',
      laborCodeId: timeCard?.laborCode?.id || '',
      divisionCodeId: timeCard?.divisionCode?.id || '',
      costCodeId: timeCard?.costCode?.id || '',
      equipmentNumber: timeCard?.equipmentNumber || '',
      supervisorId: timeCard?.supervisor?.id,
      startTime: formatTimestampForTimeInput(timeCard?.startTime),
      endTime: formatTimestampForTimeInput(timeCard?.endTime),
      isNoWorkEntry: timeCard?.isNoWorkEntry || false,
      isRepair: timeCard?.isRepair || false,
      noWorkType: timeCard?.noWorkType || '',
      workDescription: timeCard?.workDescription || '',
    });
    setSelectedCostCodeId(timeCard?.costCode?.id);
    setSelectedDivisionCodeId(timeCard?.divisionCode?.id);
    setSelectedLaborCodeId(timeCard?.laborCode?.id);
  }, [timeCard]);

  const joinTimeframeDataToDate = useCallback(
    ({ startTime, endTime }: TimeframeData): TimeframeData => {
      const dateString = moment(targetDate).format('YYYY-MM-DD');
      return {
        startTime: [dateString, 'T', startTime].join(''),
        endTime: [dateString, 'T', endTime].join(''),
      };
    },
    [targetDate],
  );

  const formFields: FormFieldsObject = useMemo(() => {
    const laborCodeOptions =
      extractCodeOptions('laborCodes')(associatedJobCodes);
    const divisionCodeOptions =
      extractCodeOptions('divisionCodes')(associatedJobCodes);
    const costCodeOptions = extractCodeOptions('costCodes')(associatedJobCodes);
    const selectedDivisionCode = associatedJobCodes?.divisionCodes.find(
      (divCode: Schemas['DivisionCodeItemResponse']) =>
        divCode.id === selectedDivisionCodeId,
    );
    console.log({
      associatedJobCodes,
      laborCodeOptions,
      divisionCodeOptions,
      costCodeOptions,
    });
    return {
      duration: {
        type: 'Timeframe',
        dataFieldKey: ['startTime', 'endTime'],
        label: 'Duration',
        required: true,
        // No-work checkbox in top right
        labelRightElement: {
          type: 'Checkbox',
          dataFieldKey: 'isNoWorkEntry',
          label: 'No work',
          size: 'lg',
          // nullify noWorkType when noWorkEntry is unchecked
          onChange: (val: boolean, data, setData) => {
            if (!val) {
              setData({
                ...data,
                noWorkType: '',
              });
            }
          },
        },
        timezone:
          mode === 'create' && moment().tz(moment.tz.guess()).format('z'),
        // The input only manages time, but the API expects a full date-time string
        // preSubmitTransform: joinTimeframeDataToDate,
        validator: (timeframeData: TimeframeData) => {
          const { startTime, endTime } = joinTimeframeDataToDate(timeframeData);
          const start = moment(startTime);
          const end = moment(endTime);
          // handle startTime is less than endTime
          if (start.isAfter(end)) {
            return 'Start time must be before end time';
          }
          // handle 0 duration
          if (start.isSame(end)) {
            return 'Start and end times cannot be the same';
          }
          // ensure both start and end use 15 minute increments
          if (
            start.minute() % 15 !== 0 ||
            end.minute() % 15 !== 0 // ||
            // start.second() !== 0 ||
            // end.second() !== 0
          ) {
            return 'Start and end times must be in 15 minute increments';
          }
        },
        disabled: (data: typeof defaults) => {
          return (
            data?.isNoWorkEntry && !['PTO', 'ESST'].includes(data?.noWorkType)
          );
        },
      },

      noWorkType: {
        type: 'Select',
        dataFieldKey: 'noWorkType',
        label: 'No work type',
        placeholder: 'Select a no-work type',
        options: {
          PTO: 'PTO',
          Holiday: 'Holiday',
          ESST: 'ESST',
          'Rain Day': 'RainDay',
          'Day Off': 'DayOff',
        },
        hidden: (formData: any) => {
          return !formData.isNoWorkEntry;
        },
        required: true,
      },

      employeeId: {
        type: 'Select',
        dataFieldKey: 'employeeId',
        label: 'Employee',
        options:
          mode === 'edit' ? [timeCard?.employee?.id] : employeeOptions ?? {},
        required: true,
        hidden: () => mode === 'edit',
      },

      jobNumber: {
        type: 'Select',
        dataFieldKey: 'jobId',
        label: 'Job number',
        placeholder: 'Select job number',
        options: jobOptions,
        required: true,
        hidden: (data: typeof defaults) => data?.isNoWorkEntry === true,
        onChange: (val: string) => {
          console.log('jobNumber onChange val: ', val);
          setSelectedJobId(val);
        },
      },

      jobName: {
        type: 'Text',
        dataFieldKey: 'jobName',
        label: 'Job name',
        readonly: true,
        populate: (data: any) => {
          if (!allJobs) {
            return 'No description';
          }
          return (
            allJobs.find((j: Schemas['JobCodeModel']) => j.id === data.jobId)
              ?.description || 'No description'
          );
        },
        hidden: (data: typeof defaults) => data?.isNoWorkEntry === true,
      },

      laborCodeId: {
        type: 'Select',
        dataFieldKey: 'laborCodeId',
        label: 'Labor code',
        placeholder: 'Select labor code',
        options: laborCodeOptions || {},
        hidden: (data: typeof defaults) => data?.isNoWorkEntry === true,
        validator: (val: string) => {
          if (!Object.values(laborCodeOptions || {}).includes(val)) {
            return 'Invalid labor code for the selected Job Number';
          }
        },
        required: true,
        disabled: !selectedJobId,
        onChange: (val) => {
          setSelectedLaborCodeId(val);
        },
        pending: selectedJobId && associatedJobCodesPending,
      },

      isRepair: {
        type: 'Radio',
        options: {
          Yes: true,
          No: false,
        },
        dataFieldKey: 'isRepair',
        label: 'Repair?',
        hidden: (data: typeof defaults) => data?.isNoWorkEntry === true,
      },

      divisionCodeId: {
        type: 'Select',
        dataFieldKey: 'divisionCodeId',
        label: 'Division code',
        placeholder: 'Select division code',
        options: divisionCodeOptions || {},
        hidden: (data: typeof defaults) => data?.isNoWorkEntry === true,
        required: true,
        onChange: (val: string) => {
          console.log('divisionCodeId onChange val: ', val);
          setSelectedDivisionCodeId(val);
        },
        validator: (val: string) => {
          if (!Object.values(divisionCodeOptions || {}).includes(val)) {
            return 'Invalid division code for the selected Job Number';
          }
        },
        disabled: !selectedJobId,
        pending: selectedJobId && associatedJobCodesPending,
      },

      costCodeId: {
        type: 'Select',
        dataFieldKey: 'costCodeId',
        label: 'Cost code',
        placeholder: 'Select cost code',
        options: costCodeOptions || {},
        hidden: (data: typeof defaults) => data?.isNoWorkEntry === true,
        required: true,
        validator: (val: string) => {
          if (!Object.values(costCodeOptions || {}).includes(val)) {
            return 'Invalid cost code for the selected Division Code';
          }
        },
        disabled: !selectedDivisionCode,
        pending: selectedDivisionCode && associatedJobCodesPending,
      },

      equipmentNumber: {
        type: 'Text',
        dataFieldKey: 'equipmentNumber',
        label: 'Equipment number',
        placeholder: 'Enter equipment number',
        hidden: (data: typeof defaults) => data?.isNoWorkEntry === true,
        onChange: setEnteredEquipmentNumber,
      },

      equipmentDescription: {
        type: 'Text',
        dataFieldKey: 'equipmentDescription',
        label: 'Equipment description',
        populate: () => enteredEquipmentEntity?.description || 'No description',
        hidden: (data: typeof defaults) => data?.isNoWorkEntry === true,
        readonly: true,
        pending:
          (selectedLaborCodeId && equipmentPending) || equipmentEntityPending,
      },

      supervisorId: {
        type: 'Select',
        dataFieldKey: 'supervisorId',
        label: 'Supervisor',
        placeholder: 'Select a supervisor',
        options: Object.fromEntries(
          (supervisorOptions || []).map((opt: Schemas['UserModel']) => [
            opt.fullName,
            opt.id,
          ]),
        ),
        required: true,
        hidden: (data: typeof defaults) =>
          data?.isNoWorkEntry === true &&
          !['PTO', 'ESST'].includes(data?.noWorkType),
      },

      workDescription: {
        type: 'Text',
        dataFieldKey: 'workDescription',
        label: 'Work description',
        multiline: true,
        placeholder: 'Describe the work completed',
        hidden: (data: typeof defaults) =>
          data?.isNoWorkEntry === true &&
          !['PTO', 'ESST'].includes(data?.noWorkType),
        required: true,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    associatedJobCodes,
    mode,
    timeCard?.employee?.id,
    employeeOptions,
    jobOptions,
    selectedJobId,
    associatedJobCodesPending,
    selectedLaborCodeId,
    equipmentPending,
    equipmentEntityPending,
    supervisorOptions,
    selectedDivisionCodeId,
    selectedCostCodeId,
    joinTimeframeDataToDate,
    allJobs,
    enteredEquipmentEntity?.description,
  ]);

  if (
    jobsPending ||
    supervisorsPending ||
    !defaults ||
    !formFields ||
    (mode === 'create' && !employeeOptions)
  ) {
    return (
      <Center h="$full" minHeight="$96" flex={1}>
        <Spinner />
      </Center>
    );
  }

  return (
    <Form
      formData={defaults}
      fields={formFields}
      onSubmit={submit}
      {...formProps}
    />
  );
};
