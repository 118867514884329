import { useEffect, useState } from 'react';
import { PageWrapper } from '../../../components/general/PageWrapper.tsx';
import {
  Box,
  Card,
  Center,
  Divider,
  Icons,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { EntityCardColumn } from '../../../components/general/EntityCardExplorer/EntityCardColumnsView/EntityCardColumn/EntityCardColumn.tsx';
import { useQuery } from '@tanstack/react-query';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import {
  Button,
  ButtonIcon,
  ButtonText,
  HStack,
  ScrollView,
  Text,
} from '@gluestack-ui/themed';
import { EquipmentNumberForm } from '../../../components/general/Form/specific/EquipmentNumberForm/EquipmentNumberForm.tsx';
import { NewEquipmentNumberModal } from '../../../components/general/Form/specific/EquipmentNumberForm/NewEquipmentNumberModal.tsx';
import { ImportNewEquipmentModal } from '../../../components/general/Form/specific/EquipmentNumberForm/ImportNewEquipmentModal.tsx';
import { JobNumberForm } from '../../../components/general/Form/specific/JobNumberForm/JobNumberForm.tsx';
import { NewJobNumberFormModal } from '../../../components/general/Form/specific/JobNumberForm/NewJobNumberFormModal.tsx';
export type JobModel = Schemas['JobModel'];
export type EquipmentModel = Schemas['EquipmentModel'];

interface ManagePageProps {}

export const ManagePage = (_props: ManagePageProps) => {
  const [focusedItem, setFocusedItem] = useState<
    EquipmentModel | JobModel | null
  >(null);
  const [showNewEquipmentNumberModal, setShowNewEquipmentNumberModal] =
    useState(false);
  const [showNewJobNumberModal, setShowNewJobNumberModal] = useState(false);
  const [showImportNewEquipmentModal, setShowImportNewEquipmentModal] =
    useState(false);
  // ensure only one modal is open at a time
  useEffect(() => {
    if (showNewEquipmentNumberModal) {
      setShowNewJobNumberModal(false);
      setShowImportNewEquipmentModal(false);
    } else if (showNewJobNumberModal) {
      setShowNewEquipmentNumberModal(false);
      setShowImportNewEquipmentModal(false);
    } else if (showImportNewEquipmentModal) {
      setShowNewJobNumberModal(false);
      setShowNewEquipmentNumberModal(false);
    }
  }, [
    showNewJobNumberModal,
    showNewEquipmentNumberModal,
    showImportNewEquipmentModal,
  ]);

  const {
    data: jobs,
    isLoading: isLoadingJobs,
    refetch: refetchJobs,
  } = useQuery({
    queryKey: ['jobs'],
    queryFn: async () =>
      await DB.GET('/jobs').then(
        (res: { data: Schemas['JobResponse'][] }) => res.data,
      ),
  });

  const {
    data: equipment,
    isLoading: isLoadingEquipment,
    refetch: refetchEquipment,
  } = useQuery({
    queryKey: ['equipment'],
    queryFn: async () =>
      await DB.GET('/equipment').then(
        (res: { data: Schemas['EquipmentListResponse'] }) => res.data.equipment,
      ),
  });

  return (
    <PageWrapper
      ToolbarContent={
        <HStack
          w="$full"
          space="lg"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            size="lg"
            onPress={() => setShowNewEquipmentNumberModal(true)}
            gap="$2"
          >
            <ButtonIcon as={Icons.Plus} />
            <ButtonText>New Equipment Number</ButtonText>
          </Button>
          <Button size="lg" onPress={() => setShowNewJobNumberModal(true)}>
            <ButtonIcon as={Icons.Plus} />
            <ButtonText>New Job Number</ButtonText>
          </Button>
          <Button
            size="lg"
            onPress={() => setShowImportNewEquipmentModal(true)}
            gap="$2"
          >
            <ButtonIcon as={Icons.Upload} />
            <ButtonText>Import Equipment Data</ButtonText>
          </Button>
        </HStack>
      }
    >
      <HStack h="$full" w="$full" pt="$4" pl="$4" space="lg">
        <EntityCardColumn
          items={jobs}
          pending={isLoadingJobs}
          categoryName="Job Numbers"
          searchByPaths={['address', 'description', 'number']}
          CardComponent={({ data }: { data: JobModel }) => (
            <ItemNumberCard
              number={data.number}
              description={data.description}
              onPress={() => setFocusedItem(data)}
              minWidth="$48"
              isSelected={focusedItem?.id === data.id}
            />
          )}
          containerProps={{
            maxWidth: '$80',
            minWidth: '$56',
          }}
        />
        <Divider subtle orientation="vertical" />
        <EntityCardColumn
          items={equipment}
          pending={isLoadingEquipment}
          categoryName="Equipment Numbers"
          searchByPaths={['number', 'description', 'notes']}
          CardComponent={({ data }: { data: EquipmentModel }) => (
            <ItemNumberCard
              number={data.number}
              description={data.description}
              onPress={() => setFocusedItem(data)}
              minWidth="$48"
              isSelected={focusedItem?.id === data.id}
            />
          )}
          containerProps={{
            maxWidth: '$80',
            minWidth: '$56',
          }}
        />
        <HStack h="$full" flex={focusedItem ? 2 : 1}>
          <Divider subtle orientation="vertical" />
          <VStack h="$full" space="lg" w="$full">
            <ViewItemDetails
              key={focusedItem?.id || 'empty'}
              item={focusedItem}
              refetchJobs={refetchJobs}
              refetchEquipment={refetchEquipment}
            />
          </VStack>
        </HStack>
      </HStack>

      {/* New Entitity Modals */}
      <NewEquipmentNumberModal
        isOpen={showNewEquipmentNumberModal}
        onClose={() => setShowNewEquipmentNumberModal(false)}
        onSuccess={refetchEquipment}
      />
      <NewJobNumberFormModal
        isOpen={showNewJobNumberModal}
        onClose={() => setShowNewJobNumberModal(false)}
        onSuccess={refetchJobs}
      />
      <ImportNewEquipmentModal
        isOpen={showImportNewEquipmentModal}
        onClose={() => setShowImportNewEquipmentModal(false)}
        onSuccess={refetchEquipment}
      />
    </PageWrapper>
  );
};

export interface ItemNumberCardProps {
  number: string | number;
  description: string;
  onPress: () => void;
  isSelected?: boolean;
}

export const ItemNumberCard = ({
  number,
  description,
  onPress,
  isSelected,
}: ItemNumberCardProps) => {
  return (
    <Card
      onPress={onPress}
      hardShadow="1"
      borderWidth={2}
      borderColor={isSelected ? '$primary600' : 'transparent'}
    >
      <VStack gap="$0.5" flex={1}>
        <Text bold>{number}</Text>
        <Text>{description}</Text>
      </VStack>
    </Card>
  );
};

const ViewItemDetails = ({
  item,
  refetchJobs,
  refetchEquipment,
}: {
  item: JobModel | EquipmentModel | null;
  refetchJobs?: () => void;
  refetchEquipment?: () => void;
}) => {
  if (item === null) {
    return (
      <Center flex={1}>
        <Text size="lg">Select code to view</Text>
      </Center>
    );
  }

  const isJob = 'address' in item;

  return (
    <VStack space="lg" h="$full">
      <Center h="$16" bg="$primary700">
        <Text bold size="xl" color="$white">
          {`${isJob ? 'Job' : 'Equipment'} #${item.code || item.number}`}
        </Text>
      </Center>
      <Box flex={1} w="$full" h="$full" px="$12">
        <ScrollView py="$10" px="$0.5" h="$full">
          {isJob ? (
            <JobNumberForm partialJobNumber={item} onSuccess={refetchJobs} />
          ) : (
            <EquipmentNumberForm
              equipmentNumberData={item}
              onSuccess={refetchEquipment}
            />
          )}
        </ScrollView>
      </Box>
    </VStack>
  );
};
