import React from 'react';
import {
  Card,
  Icons,
  HStack,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import { Icon, Text } from '@gluestack-ui/themed';
import moment from 'moment';
import { Schemas } from '@tonic/central-specialties-utils';

interface TruckRequestCardProps {
  truckRequest: Schemas['TruckingRequestResponse'];
  onPress: (truckRequest: Schemas['TruckingRequestResponse']) => void;
  cardProps?: React.ComponentProps<typeof Card>;
}

export const TruckRequestCard = ({
  truckRequest,
  onPress,
  cardProps,
}: TruckRequestCardProps) => {
  return (
    <Card onPress={() => onPress(truckRequest)} {...cardProps}>
      <HStack alignItems="center" justifyContent="center" flex={1} gap="$4">
        <VStack flex={1} gap="$0.5" justifyContent="space-between">
          <VStack gap="$0.5">
            <Text bold>
              Request {truckRequest.requestNumber || '(number pending)'}
            </Text>
            {!!truckRequest?.job && (
              <Text>Job number: {truckRequest.job.number}</Text>
            )}
            <Text>
              Start time:{' '}
              {moment(truckRequest.startDate)
                .utc(true)
                .tz('America/Chicago')
                .format('M/D/YY')}{' '}
              {truckRequest.startTime}
            </Text>

            {truckRequest.trucks.length > 0 &&
              truckRequest.trucks.map((truck) => (
                <Text key={truck.type + truck.companyName}>
                  Truck: {truck.type} ({truck.numberOfTrucks}) -{' '}
                  {truck.companyName} {truck.dbe ? '(DBE)' : ''}
                </Text>
              ))}
          </VStack>
        </VStack>
        {truckRequest.hasMessages && (
          <Icon as={Icons.MessageCircle} size="xl" color="$textSubtle" />
        )}
      </HStack>
    </Card>
  );
};
